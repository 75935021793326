<template>
  <div class="input-data">
    <div>
      <section>
        <div class="is-flex is-align-items-start is-justify-content-space-between is-flex-wrap-wrap">
          <div class="is-flex is-flex-wrap-wrap">
            <sg-select :items="[{block_name: $t('all'), prepend: false},...selectedFarm.blocks]"
                       type="is-secondary is-light"
                       :prepend="$t('farm.block')"
                       class="mb-2 mr-2"
                       label="block_name"
                       v-model="selectedBlock"
                       :placeholder="`${$t('select')} ${$t('farm.block')}`"/>

            <b-datepicker
                class="mb-2 mr-2"
                :placeholder="$t('date')"
                icon="calendar-text"
                locale="id-ID"
                v-model="selectedDate"
                editable/>
          </div>

          <b-button type="is-text" class="has-text-info has-shadow mb-2" @click="resetForm()" icon-left="sync">
            Reset Form
          </b-button>
        </div>

        <div class="b-table custom-table" v-if="filteredData.length">
          <div class="table-wrapper has-sticky-header" :class="{embed: embed}">
            <table class="table is-bordered">
              <thead>
              <tr class="header-row">
                <th class="has-text-centered" rowspan="2">{{ $t('farm.pond') }}</th>
                <th rowspan="2" style="min-width: 280px">{{ $t('warehouse.feed_name') }}</th>
                <th :colspan="times.length">{{ $t('warehouse.feed_schedule') }}</th>
                <th rowspan="2" style="min-width: 180px">{{ $t('note.note') }}</th>
              </tr>
              <tr>
                <th class="p-0" v-for="n in times.length" :key="`time${n}`">
                  <b-timepicker
                      :placeholder="$t('time')"
                      icon="calendar-text"
                      locale="id-ID"
                      append-to-body
                      v-model="times[n - 1]"
                      style="min-width: 120px"
                      editable/>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, key) in filteredData" :key="`data-${key}`">
                <td class="has-text-centered is-sticky">
                  <div class="is-flex is-justify-content-space-between is-align-items-center">
                    {{ item.pond.name }}
                    <div class="is-flex">
                      <b-button type="is-primary" icon-left="plus" size="is-small" class="ml-4 mr-2"
                                @click="addData(item, item.key + 1)"></b-button>
                      <b-button type="is-primary" icon-left="minus" size="is-small" @click="deleteData(item.key)"
                                :disabled="totalPondData(item) === 1"></b-button>
                    </div>
                  </div>
                </td>
                <td class="p-0">
                  <b-field class="m-0">
                    <b-autocomplete
                        append-to-body
                        open-on-focus
                        keep-first
                        select-on-click-outside
                        :data="filteredProduct"
                        :placeholder="$t('search')"
                        field="product_name"
                        v-model="item.product_name"
                        @typing="searchProduct"
                        @select="option => handleSelectProduct(option, item)"/>

                    <p class="control">
                      <sg-select type="is-secondary is-light" no-outline expanded :items="unit" v-if="item.product"
                                 class="select-unit" append-to-body
                                 :disabled="!!item.product.warehouse_id"
                                 v-model="item.product.unit" :placeholder="`Unit`"></sg-select>
                    </p>
                  </b-field>
                </td>
                <td class="p-0" v-for="n in times.length" :key="`input-data-${n}`">
                  <b-field class="m-0">
                    <b-input ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm) + (n - 1))"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             placeholder="0.0" expanded type="number" step="any"
                             v-model.number="item.qty[n - 1]"></b-input>
                  </b-field>
                </td>
                <td class="p-0">
                  <b-field class="m-0">
                    <b-input ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm) + 5)"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             :placeholder="`Input ${$t('note.note')}`" expanded v-model="item.desc"></b-input>
                  </b-field>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p class="has-text-danger" v-else>No Ponds have been stocked yet <span
            v-if="selectedBlock && selectedBlock.block_id">on Block {{ selectedBlock.block_name }}</span></p>
      </section>

      <footer class="is-flex is-justify-content-flex-end mt-3" v-if="filteredData.length">
        <b-button type="is-primary is-gradient" class="ml-2" @click="handleSubmit">{{ $t('save') }}</b-button>
      </footer>
    </div>

    <b-modal
        v-model="warehouseEmptyModal"
        has-modal-card
        :destroy-on-hide="false"
        aria-role="dialog"
        class="modal-center"
        :width="480"
        aria-modal>
      <template #default>
        <div class="card">
          <div class="card-content has-text-centered">
            <p class="is-size-5 has-text-weight-bold">{{ $t('warehouse.feed_empty') }}</p>

            <div class="is-flex is-justify-content-center mt-5">
              <b-button tag="router-link" :to="`/farm/${selectedFarm.farm_id}/warehouse`" type="is-primary"
                        class="ml-3">{{ $t('add') }} {{ $t('warehouse.product') }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "InputFeedBulk",
  components: {SgSelect},
  computed: {
    ...mapGetters('farm', [
      'isPro',
      'unit',
    ]),

    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    filteredPonds() {
      if (this.selectedBlock && this.selectedFarm.ponds) return this.selectedFarm.ponds.filter(e => e.block_id === this.selectedBlock.block_id)
      else return []
    },
    filteredPondsInput() {
      return this.selectedFarm.ponds.filter(e => e.status >= 1)
    },
    filteredData() {
      let data = this.data.filter(e => e.pond.status >= 2)
      if (this.selectedBlock && this.selectedBlock.block_id) data = data.filter(e => e.pond.block_id === this.selectedBlock.block_id)
      return data
    },

    embed() {
      return this.$route.query.output === 'embed'
    },
  },
  data: () => {
    return {
      filteredProduct: [],
      products: [],
      isFetchingProduct: false,

      // data: [{show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}, {show_preset: false}],
      times: [],
      data: [],
      selectedBlock: null,
      selectedDate: null,

      selectedInput: 0,
      maxInput: 0,
      maxInputForm: 0,

      warehouseEmptyModal: false,
    }
  },
  watch: {
    'selectedFarm.blocks'(value) {
      this.selectedBlock = value[0]
    },
    'selectedFarm.ponds'() {
      this.init()
    },
    data: {
      deep: true,
      handler(value) {
        localStorage.setItem('sgara.pgsql.input.feed.data', JSON.stringify(value))
      },
    },
    selectedDate: {
      deep: true,
      handler(value) {
        localStorage.setItem('sgara.pgsql.input.feed.date', JSON.stringify(value))
      },
    },
    times: {
      deep: true,
      handler(value) {
        localStorage.setItem('sgara.pgsql.input.feed.times', JSON.stringify(value))
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions('farm', [
      'addWarehouse',
    ]),
    addData(item, key) {
      let newItem = this._.cloneDeep(item)
      this.data.splice(key, 0, {
        pond: newItem.pond,
        qty: [],
      })
      this.resetKey()
    },

    deleteData(key) {
      this.data.splice(key, 1)
      this.resetKey()
    },

    totalPondData(item) {
      return this.data.filter(e => e.pond.pond_id === item.pond.pond_id).length
    },

    resetKey() {
      this.data = this.data.map((e, key) => {
        return {
          ...e,
          key
        }
      })
    },

    handleFocus(n) {
      this.selectedInput = n
      this.maxInput = this.$refs[`input-form`].length - 1
    },

    handleArrowRight() {
      if (this.selectedInput >= this.maxInput) this.selectedInput = -1
      let find = document.querySelectorAll(`.input-form`)[this.selectedInput + 1]
      this.selectElement(find)
    },

    handleArrowLeft() {
      if (this.selectedInput <= 0) this.selectedInput = document.querySelectorAll(`.input-form:not(:disabled)`).length
      let find = document.querySelectorAll(`.input-form:not(:disabled)`)[this.selectedInput - 1]
      this.selectElement(find)
    },

    handleArrowDown() {
      if (this.selectedInput + this.maxInputForm > this.maxInput) this.selectedInput = -(this.maxInput + 1 - this.selectedInput) + this.maxInputForm
      else this.selectedInput += this.maxInputForm
      let find = document.querySelectorAll(`.input-form:not(:disabled)`)[this.selectedInput]
      this.selectElement(find)
    },

    handleArrowUp() {
      if (this.selectedInput - this.maxInputForm < 0) this.selectedInput = this.maxInput + this.selectedInput + 1
      this.selectedInput -= this.maxInputForm
      let find = document.querySelectorAll(`.input-form:not(:disabled)`)[this.selectedInput]
      this.selectElement(find)
    },

    selectElement(el) {
      if (el) {
        if (el.querySelector('input')) el.querySelector('input').focus()
        else if (el.querySelector('button')) el.querySelector('button').focus()
      }
    },

    ...mapActions('farm', [
      'getWarehouse',
    ]),

    async init() {
      if (this.selectedFarm.ponds.length) {
        if (this.selectedFarm.blocks.length) this.selectedBlock = this.selectedFarm.blocks[0]

        let data = JSON.parse(localStorage.getItem('sgara.pgsql.input.feed.data'))
        let date = JSON.parse(localStorage.getItem('sgara.pgsql.input.feed.date'))
        let times = JSON.parse(localStorage.getItem('sgara.pgsql.input.feed.times'))

        // feed 5, chemical 3, prob 6

        this.$loading()
        this.products = []
        await this.getWarehouse({
          farm_id: this.selectedFarm.farm_id,
          category_id: 5,
        }).then(res => {
          if (res && res.data && res.data.length) this.products = res.data
        }).finally(() => {
          this.resetForm(data, date, times)
          this.$loading(false)
        })
      }
    },

    resetForm(data = null, date = null, time = null) {
      this.data = []
      this.times = []
      this.selectedDate = date ? new Date(date) : new Date()

      let times = ['07:00', '10:00', '13:00', '16:00', '19:00', '22:00']

      if (time) {
        for (let i = 0; i < time.length; i++) {
          this.times.push(new Date(time[i]))
        }
      } else {
        for (let i = 0; i < times.length; i++) {
          this.times.push(new Date(this.$dateFormat(null, 'YYYY-MM-DD') + ' ' + times[i]))
        }
      }

      this.maxInputForm = 6

      if (data && data.length) {
        this.data = data
      } else {
        for (let i = 0; i < this.filteredPondsInput.length; i++) {
          let find = data ? data.find(e => e.pond.pond_id === this.filteredPondsInput[i].pond_id) : null
          this.data.push({
            pond: this.filteredPondsInput[i],
            product: find ? find.product : null,
            product_name: find && find.product ? find.product.product_name : null,
            qty: find ? find.qty : [],
          })
        }
      }

      this.$nextTick(() => {
        this.$loading(false)
      })
    },
    handleSelectProduct(option, item) {
      item.product = option
      if (item.product) {
        item.product.product_name = item.product.product_name.replace(' (Add New)', '')
        item.product_name = item.product.product_name
        if (!item.product.unit) item.product.unit = 'Kg'
      }
      this.filteredProduct = []
    },

    async handleSubmit() {
      this.$loading()

      let data = []
      let ponds = []

      data = this.filteredData.reduce((a, b) => {
        if (b.qty.find(e => e)) {
          a.push(b)
          if (!ponds.find(f => f.pond_id === b.pond.pond_id)) ponds.push(b.pond)
        }
        return a
      }, data)


      let newProducts = data.reduce((a, b) => {
        if (b.product.product_name && !b.product.warehouse_id) {
          let find = a.find(e => e.product_name === b.product.product_name)
          if (!find) a.push({
            ...b.product,
            category_id: 5,
            farm_id: this.selectedFarm.farm_id,
            mode: 0,
          })
        }
        return a
      }, [])

      if (newProducts.length) {
        for (let i = 0; i < newProducts.length; i++) {
          let product = newProducts[i]
          await this.addWarehouse({
            farm_id: this.selectedFarm.farm_id,
            category_id: 5,
            mode: 0,
            desc: '',
            product_name: product.product_name,
            product_price: 0,
            qty: 0,
            unit: product.unit,
            min_qty: 0,
            synthesize: false,
            component: null,
            created_at: this.$timestamp(),
          }).then((res) => {
            if (res.data && res.data.id) {
              product.warehouse_id = res.data.id
            }
          })
        }
        data = data.map(e => {
          if (e.product.product_name && !e.product.warehouse_id) {
            let find = newProducts.find(f => f.product_name == e.product.product_name)
            if (find) {
              e.product.warehouse_id = find.warehouse_id
              e.product.category_id = 5
            }
          }
          return e
        })
      }

      for (let i = 0; i < ponds.length; i++) {
        let cycle = await this.$store.dispatch('farm/getActiveCycle', ponds[i].pond_id)
        if (cycle) ponds[i].cycle = cycle
      }

      let payload = []
      let timediff = new Date().getTimezoneOffset() * -1

      for (let i = 0; i < data.length; i++) {
        let item = data[i]
        let pond = ponds.find(e => e.pond_id === item.pond.pond_id)
        if (pond && pond.cycle) {
          if (item.product) {
            for (let j = 0; j < this.times.length; j++) {
              let time = this.times[j]
              let qty = item.qty[j]
              if (qty) {
                let date = this.$timestamp(this.$dateFormat(this.selectedDate, 'YYYY-MM-DD') + ' ' + this.$timeFormat(time))
                payload.push({
                  farm_id: item.product.farm_id,
                  pond_id: pond.pond_id,
                  block_id: pond.block_id,
                  cycle_id: pond.cycle.cycle_id,
                  warehouse_id: item.product.warehouse_id,
                  component_id: 0,
                  product_name: item.product.product_name,
                  product_price: item.product.product_price,
                  category_id: item.product.category_id,
                  mode: item.product.mode,
                  unit: item.product.unit,
                  created_at: date,
                  qty: qty,
                  desc: item.desc || '',
                  date: this.$startDay(date).valueOf(),
                  timediff,
                })
              }
            }
          }
        }
      }

      await this.$store.dispatch('farm/useItem', payload)

      this.$emit('close')
      this.$emit('submit')
      this.$loading(false)

      localStorage.removeItem('sgara.pgsql.input.feed.data')
      localStorage.removeItem('sgara.pgsql.input.feed.date')
      localStorage.removeItem('sgara.pgsql.input.feed.times')

      this.resetForm()

      this.$router.push('/farm/' + this.selectedFarm.farm_id)
    },
    searchProduct(value) {
      let search = value.trim().toLowerCase()

      if (search) {

        this.filteredProduct = this.products.filter(e => e.product_name.trim().toLowerCase().includes(search)).sort((a, b) => a.product_name.localeCompare(b.product_name, undefined, {
          numeric: true,
          sensitivity: 'base',
        }))

        let find = this.filteredProduct.find(e => e.product_name.trim().toLowerCase() === search)

        if (!find) {
          this.filteredProduct = [
            {
              product_name: value.trim() + ' (Add New)',
            },
            ...this.filteredProduct,
          ]
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  width: 1200px !important;
  max-width: 100%;
}
</style>

<style lang="scss">
.input-data {
  .button.is-small {
    padding: 0.05rem 0.75rem;
  }

  .select-unit {
    min-width: unset !important;
    width: 100px;
  }
}
</style>
