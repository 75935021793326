var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-dropdown',{staticClass:"sg-select",class:{
              'w-100': _vm.expanded
            },attrs:{"aria-role":"list","tabindex":"0","multiple":_vm.multiple,"disabled":_vm.disabled,"append-to-body":_vm.appendToBody},on:{"change":function (value) { return _vm.handleChangeValue(value); }},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
            var active = ref.active;
return _c('b-button',{class:{
    'border-danger': _vm.isError
  },attrs:{"outlined":!_vm.noOutline,"type":_vm.type,"expanded":""},on:{"focus":function($event){return _vm.$emit('focus')}}},[_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-space-between"},[_c('span',{staticClass:"text",class:{
        'is-null': (_vm.multiple ? (!_vm.value || !_vm.value.length) : !_vm.value)
      }},[_vm._v(" "+_vm._s(_vm.isValueNotNull ? _vm.labelValue : _vm.placeholder))]),_c('b-icon',{attrs:{"icon":active ? 'chevron-up' : 'chevron-down'}})],1)])}}]),model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},_vm._l((_vm.items),function(item,key){return _c('b-dropdown-item',{key:key,attrs:{"aria-role":"listitem","value":item}},[_c('span',[_vm._v(_vm._s((item.prepend === false ? '' : _vm.prepend) + ' ' + (typeof item === 'object' ? item[_vm.label] : item) + ' ' + _vm.append))])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }