<template>
  <b-dropdown aria-role="list" class="sg-select"
              tabindex="0"
              v-model="selectedValue"
              :class="{
                'w-100': expanded
              }"
              :multiple="multiple"
              :disabled="disabled"
              :append-to-body="appendToBody"
              @change="value => handleChangeValue(value)"
  >
    <b-button @focus="$emit('focus')" :outlined="!noOutline" slot="trigger" slot-scope="{ active }" :type="type" expanded :class="{
      'border-danger': isError
    }">
      <div class="is-flex is-align-items-center is-justify-content-space-between">
        <span class="text" :class="{
          'is-null': (multiple ? (!value || !value.length) : !value)
        }">
          {{ isValueNotNull ? labelValue : placeholder }}</span>
        <b-icon :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
      </div>
    </b-button>

    <b-dropdown-item aria-role="listitem" :value="item"
                     v-for="(item, key) in items" :key="key">
      <span>{{ (item.prepend === false ? '' : prepend) + ' ' + (typeof item === 'object' ? item[label] : item) + ' ' + append }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: "SgSelect",
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
    value: {
      default: ''
    },
    items: {
      type: Array
    },
    placeholder: {
      type: String
    },
    type: {
      type: String
    },
    noOutline: {
      type: Boolean,
      default: true
    },
    expanded: {
      type: Boolean
    },
    multiple: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'label',
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    prepend: {
      type: String,
      default: '',
    },
    append: {
      type: String,
      default: '',
    },
  },
  data: () => {
    return {
      selectedValue: null
    }
  },
  created() {
    this.selectedValue = this.value
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.selectedValue = this.value
      }
    },
  },
  computed: {
    isValueNotNull() {
      return this.multiple ? this.value && this.value.length : !!this.value
    },
    labelValue() {
      if (this.isValueNotNull) {
        if (this.multiple) {
          return typeof this.value[0] !== "object" ? ((this.value && this.value.prepend === false ? '' : this.prepend) + ' ' + this.value + ' ' + this.append).join(' • ') : this.value.map(e => this.prepend + ' ' + e[this.label] + ' ' + this.append).join(' • ')
        } else {
          return (this.value && this.value.prepend === false ? '' : this.prepend) + ' ' + (typeof this.value === 'object' ? this.value[this.label] : this.value) + ' ' + this.append
        }
      }
      return ''
    },
  },
  methods: {
    handleChangeValue(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss">
.sg-select {
  min-width: 140px;

  .button {
    .text{
      white-space: normal;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    &.is-secondary {
      padding-left: calc(0.75em - 1px);
      background-color: #F7F7F7;
    }

    &.is-light {
      background-color: #F7F7F7 !important;

      &:focus {
        background-color: #000;
      }
    }
  }

  &.is-active {
    .button {
      &.is-light {
        background-color: #F2F2F2 !important;
      }
    }
  }

  span.is-null {
    color: #bcbcbc !important;
  }

  .dropdown-content {
    max-height: 300px;
    overflow: auto;
  }
}
</style>
