<template>
  <div class="input-data">
    <h2 class="is-size-5 has-text-weight-bold has-text-dark mb-4">{{ $t('warehouse.feed') }}</h2>
    <input-feed-bulk/>
  </div>
</template>

<script>

import InputFeedBulk from "@/components/Farm/UseItem/InputFeedBulk";
export default {
  name: "InputFeedForm",
  components: {
    InputFeedBulk
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    embed() {
      return this.$route.query.output === 'embed'
    },
  },
}
</script>
